const randomPicker = {
  pageName: {
    en: `Random picker`,
    th: `จับฉลาก`,
  },
  title: {
    en: `Random picker`,
    th: `จับฉลาก`,
  },
  head: {
    listItems: {
      en: `List Items`,
      th: `รายการฉลาก`,
    },
    pickedUpListItems: {
      en: `Picked Up List Items`,
      th: `รายการฉลากที่จับแล้ว`,
    },
    count: {
      en: {
        eqOne: `item`,
        neqOne: `items`,
      },
      th: `รายการ`,
    },
  },
  notice: {
    listItem: {
      en: `There is no any items here.`,
      th: `ยังไม่มีรายการฉลาก`,
    },
    pickedUpListItems: {
      en: `This box is empty.`,
      th: `ยังไม่มีรายการในกล่องนี้`,
    },
  },
  label: {
    menu: {
      en: `Menu`,
      th: `เมนู`,
    },
    option: {
      en: `Options`,
      th: `ตัวเลือก`,
    },
  },
  button: {
    add: {
      en: `Add`,
      th: `เพิ่ม`,
    },
    start: {
      en: `Start Random`,
      th: `เริ่มสุ่ม`,
    },
    stop: {
      en: `Tap to stop`,
      th: `แตะเพื่อหยุด`,
    },
    resume: {
      en: `Retry`,
      th: `สุ่มอีกครั้ง`,
    },
    end: {
      en: `Take out`,
      th: `หยิบออก`,
    },
    edit: {
      en: `Edit`,
      th: `แก้ไข`,
    },
    removeOne: {
      en: `Remove`,
      th: `ลบ`,
    },
    returnOne: {
      en: `Return`,
      th: `คืนกลับ`,
    },
    removeAll: {
      en: `Remove all items`,
      th: `ลบรายการฉลากทั้งหมด`,
    },
    returnAll: {
      en: `Return all items`,
      th: `คืนรายการฉลากทั้งหมด`,
    },
    restart: {
      en: `Restart`,
      th: `เริ่มใหม่`,
    },
  },
  addItemModal: {
    title: {
      en: `Add an item`,
      th: `เพิ่มรายการ`,
    },
    textBoxDescription: {
      en: `Item name`,
      th: `ชื่อรายการ`,
    },
    button: {
      confirm: {
        en: `Add`,
        th: `เพิ่ม`,
      },
      cancel: {
        en: `Cancel`,
        th: `ยกเลิก`,
      },
    },
    multi: {
      checkbox: {
        en: `Bulk add`,
        th: `เพิ่มหลายรายการ`,
      },
      title: {
        en: `Add multiple items`,
        th: `เพิ่มรายการ`,
      },
      textBoxDescription: {
        en: `(Use "Enter" to separate each item.)`,
        th: `(กด "Enter" เพื่อแบ่งระหว่างรายการ)`,
      },
    },
  },
  editItemModal: {
    title: {
      en: `Edit an item`,
      th: `แก้ไขรายการ`,
    },
    textBoxDescription: {
      en: `Item name`,
      th: `ชื่อรายการ`,
    },
    button: {
      confirm: {
        en: `Save`,
        th: `บันทึก`,
      },
      cancel: {
        en: `Cancel`,
        th: `ยกเลิก`,
      },
    },
  },
  returnPickedUpItemsModal: {
    title: {
      en: `Return all items`,
      th: `คืนรายการฉลากทั้งหมด`,
    },
    description: {
      en: `Do you want to return all items to "List Items" box?`,
      th: `คุณต้องการคืนฉลากทั้งหมดไปยังกล่อง "รายการฉลาก"?`,
    },
    button: {
      confirm: {
        en: `Return All`,
        th: `คืนกลับทั้งหมด`,
      },
      cancel: {
        en: `Cancel`,
        th: `ยกเลิก`,
      },
    },
  },
  removeAllItemsModal: {
    title: {
      en: `Remove all items`,
      th: `ลบรายการฉลากทั้งหมด`,
    },
    description: {
      en: `Do you want to remove all items from this box?`,
      th: `คุณต้องการลบรายการฉลากทั้งหมดในกล่องนี้?`,
    },
    button: {
      confirm: {
        en: `Delete All`,
        th: `ลบทั้งหมด`,
      },
      cancel: {
        en: `Cancel`,
        th: `ยกเลิก`,
      },
    },
  },
  discardAllItemsModal: {
    title: {
      en: `Restart`,
      th: `เริ่มใหม่`,
    },
    description: {
      en: `All of your items will be discarded. Do you want to restart?`,
      th: `รายการฉลากของคุณทั้งหมดจะถูกลบ คุณต้องการเริ่มใหม่หรือไม่?`,
    },
    button: {
      confirm: {
        en: `Restart`,
        th: `เริ่มใหม่`,
      },
      cancel: {
        en: `Cancel`,
        th: `ยกเลิก`,
      },
    },
  },
}

export default randomPicker
